import React from "react"
import './basic-content.scss';
import RichText from "../rich-text/rich-text"

const BasicContent = ({heading, subheading, body, className="" }) => {

  return (<div className="basic-content py-28 py-md-20">
    <div className={"container px-12 px-md-0 " + className}>
      <h2>{heading}</h2>
      <h3 className="subheading mb-6">{subheading}</h3>
      <div className="row">
        <div className="col-12 col-md-9">
          <RichText data={body} />
        </div>
      </div>
    </div>
  </div>)
}
export default BasicContent;
