import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import DefaultLayout from "../components/layout/default-layout"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import Footer from "../components/shared/footer/footer"
import BackDropped from "../components/shared/back-dropped/back-dropped"
import HeroSection from "../components/shared/hero-section/hero-section"
import BasicContent from "../components/shared/basic-content/basic-content"
import ProductList from "../components/products/product-list/product-list"

const ProductsPage = ({ locale = "en" }) => {
  const viewData = useStaticQuery(query)
  const localizedData = viewData.strapiProductsPage.localEntities.find(item => item.locale === locale) || viewData.strapiProductsPage

  const heroSectionData = localizedData.heroSection
  const theme = localizedData.pageSettings.headerTheme

  return (
    <>
      <DefaultLayout
                type={theme}
                path="products"
                heroBackground={heroSectionData.background}
                wrapperClass="bg-light"
      >
        <HeroSection {...heroSectionData} theme={theme} />
        <BasicContent {...localizedData.summary} className="py-24" />
        <ProductList locale={locale} />
      </DefaultLayout>
      <Footer locale={locale}/>
      <BackDropped locale={locale} />
    </>
  )
}

const query = graphql`
    fragment pageDataProducts on StrapiProductsPage {
        locale
        pageSettings {
            title
            description
            headerTheme
        }
        heroSection {
            heading
            topText
            background {
                alternativeText
                ext
                mime
                url
            }
            mobileBackground {
              url
            }
            actions {
                endIcon
                linkTarget
                text
                theme
                type
                wrapperClass
            }
        }
        summary {
            body
            heading: heading1
            subheading: heading2
        }

        # Duplicated above Query for localizations
        # @TODO Use Fragment here if possible instead of duplicating 

        localEntities {
            locale
            pageSettings {
                title
                description
                headerTheme
            }
            heroSection {
                heading
                topText
                background {
                    alternativeText
                    ext
                    mime
                    url
                }
                mobileBackground {
                  url
                }
                actions {
                    endIcon
                    linkTarget
                    text
                    theme
                    type
                }
            }
            summary {
                body
                heading: heading1
                subheading: heading2
            }
        }
        
    }
    query ProductsPageQuery {
        strapiProductsPage {
           ...pageDataProducts
        }
    }
`

export default ProductsPage
