import React from 'react';
import './language-btn.scss'
import { StaticImage } from "gatsby-plugin-image"

export default function LanguageBtn ({ lang='en', activeLocale }) {

  const langTexts = {
    tr: {text: 'Türkçe', key: 'tr'},
    en: {text: 'English', key: 'en'},
    de: {text: 'Deutsch', key: 'de'},
    ar: {text: 'عربي', key: 'ar'},
  };

  const changeLanguage = (_lang) => {
    const localePrefix = _lang !== "en" ? `/${_lang}` : "/"
    window.toggleLanguageSwitcher();
    window.location.assign(localePrefix);
  }

  return (
    <button className={`language-btn relative ${activeLocale === lang ? 'active' : ''}`} onClick={() => { changeLanguage(lang) }}>
      <div className={`flag-img float-left ${lang} ${lang !== 'ar' ? 'text-left' : 'text-right'}`} />
      <div className={`language-btn__info pt-2 ${lang === 'ar' ? 'float-right text-right' : 'float-left text-left'}`}>
        <div className="language-text">{ langTexts[lang].text }</div>
        <div className="language-key uppercase">{ langTexts[lang].key }</div>
      </div>
      <StaticImage className={`active-icon ${activeLocale !== lang? 'hidden' : ''}`}
                   src="../../../images/icons/checkmark.svg"
                   alt="" />
      <div className="clear-both" />
    </button>
  )
}
