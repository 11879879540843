import * as React from "react"
import LightBoxLayout from "../../layout/lightbox-layout"
import { useLayoutEffect } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade, Navigation } from 'swiper';

import "swiper/css"
import "swiper/css/pagination"
import 'swiper/css/navigation';

import "./gallery-screen.scss"

const GalleryScreen = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [currentGallery, setCurrentGallery] = React.useState(null)
  const [currentCaption, setCurrentCaption] = React.useState("")
  const instance = {
    isOpen: false,
    close: () => {
      document.querySelector("html").classList.remove("overflow-hidden")
      document.querySelector("body").classList.remove("overflow-hidden")
      setIsOpen(false)
    },
    open: (images, index=0) => {
      setCurrentGallery(images)
      setActiveIndex(index)
      setCurrentCaption(images[index].caption)
      document.querySelector("html").classList.add("overflow-hidden")
      document.querySelector("body").classList.add("overflow-hidden")
      setIsOpen(true)
    }

  }

  useLayoutEffect(() => {
    window.currentGalleryLightBox = instance
  })

  return isOpen ? (
    <LightBoxLayout grid={false} closeMe={instance.close}>
      <div className="gallery-light-box">
        <div className="container-xxl relative">
          <div className="w-full h-screen flex align-items-center">
            {isOpen ? <Swiper
              modules={[EffectFade, Navigation]}
              effect="fade"
              spaceBetween={32}
              navigation
              slidesPerView={1}
              initialSlide={activeIndex}
              speed={1000}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex)
                setCurrentCaption(currentGallery[swiper.activeIndex].caption)
              }}
            >
              {currentGallery.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img className="m-auto" src={item.url} alt={item.caption} />

                  </SwiperSlide>
                )
              })}
              <div className="gallery-pagination">
                {activeIndex + 1} / {currentGallery.length}
              </div>
              <div className="caption-row">{currentCaption}</div>
            </Swiper> : ""}

          </div>
        </div>
      </div>
    </LightBoxLayout>
  ) : ""
}

export default GalleryScreen
