import React from 'react';
import './footer.scss';
import FooterNav from './footer-nav';
import LocationsSection from "../../home/locations-section/locations-section"
import { graphql, useStaticQuery } from "gatsby"
import getLocaleEntities from "../../../helpers/localization-helper"
import FadeInConfig from "../animate-config"

const query = graphql`
    fragment PageDataHF on StrapiHeaderFooter {
        footerContext {
            heading
            subheading
            copyright
            locations {
                heading
                email
                body
                imagesToShow {
                    alternativeText
                    url
                }
            }
            action {
                linkTarget endIcon theme type text
            }
            logo {
                url alternativeText
            }
            menuItems {
                superText target text
            }
            social {
                content title
                media {
                    url alternativeText
                }
            }
        }
    }
    query {
        strapiHeaderFooter {
            ...PageDataHF
        }
    }
`;

const Footer = ({locale = "en"}) => {
  const servicePath = "strapiHeaderFooter"
  const viewData = useStaticQuery(query)
  const localizedData = getLocaleEntities({viewData, servicePath, locale})

  const { heading, subheading, copyright, action, logo, menuItems, social, locations } = localizedData.footerContext
  const [currentYear, setCurrentYear] = React.useState(0);

  const getYear = () => {
    if (currentYear !== 0) return currentYear;
    else {
      const d = new Date();
      const c = d.getFullYear();
      setCurrentYear(c);
      return c;
    }
  }

  return (
    <>
      <LocationsSection locations={locations} />
      <footer>
        <FooterNav heading={heading} subheading={subheading} menuItems={menuItems} action={action} locale={locale} />
        <div className="footer-subline py-12">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-3 justify-center md:justify-start flex align-items-center p-0">
                <img className="footer-logo" src={logo.url} alt="916 Logo" />
                <span className="footer-copyright px-3"  {...FadeInConfig}>{getYear()} {copyright}</span>
              </div>
              <div className="col-12 col-md-6 flex align-items-center justify-center p-0">
                {social.map((item, index) => <a key={index} href={item.content} target="_blank" rel="noreferrer"><img className="footer-subline__social" src={item.media.url} alt={item.media.alternativeText} /></a>)}
              </div>
              {/* Disabling multi-language temporarily
               Below section shows the button on the footer to display language switcher display
              */}
              {/*<div className="col-12 col-md-3 flex align-items-center  justify-center md:justify-end">
                <LanguageSwitcherBtn locale={locale}/>
              </div>*/}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer;
