import React from "react"
import { graphql } from "gatsby"
import Footer from "../../../components/shared/footer/footer"
import BackDropped from "../../../components/shared/back-dropped/back-dropped"
import RichText from "../../../components/shared/rich-text/rich-text"
import ArticleHeader from "../../../components/insights/article-header/article-header"
import ArticleTags from "../../../components/insights/article-tags/article-tags"
import ArticleHero from "../../../components/insights/article-hero/article-hero"
import "../../../components/insights/insights.scss"
import NewsSection from "../../../components/home/news-section/news-section"
import ArticleFooter from "../../../components/insights/article-footer/article-footer"
import ArticleWriter from "../../../components/insights/article-writer/article-writer"
import BasicLayout from "../../../components/layout/basic-layout"
import NotFoundPage from "../../404"


const InsightDetail = ({ data, locale="tr" }) => {
  const insight = data.strapiInsights;
  if (!insight || !insight.title) return <NotFoundPage />
  return <>
    <BasicLayout
      grid={false}
      theme="dark"
      path="insights"
      seo={{ title: insight.title }}
      wrapperClass="bg-light"
      >
      <ArticleHeader title={insight.title} subtitle={insight.subtitle} />
      <ArticleTags writer={insight.writer.name}
                   category={insight.category}
                   readTime={insight.readTime}
                   locale={locale}/>
      <ArticleHero videoSrc={insight.mainMedia.videoLink}
                   imgSrc={insight.mainMedia.image.url}
                   caption={insight.mainMedia.image.alternativeText}/>

      <div className="container">
        <div className="row">
          <div className="col-8 offset-2 insight-lead">
            <RichText data={insight.lead} />
          </div>
        </div>
        <div className="row">
          <div className="col-8 offset-2 insight-content">
            <RichText data={insight.content1} />
          </div>
        </div>
      </div>
      <ArticleFooter url={insight.url} title={insight.title} locale={locale}/>
      <ArticleWriter writer={insight.writer}/>
      <NewsSection locale={locale} compact />
    </BasicLayout>
    <Footer locale={locale}/>
    <BackDropped locale={locale} />
  </>
}

export const query = graphql`
    query ($url: String!){
        strapiInsights(url:{eq: $url} locale: {eq: "tr"}) {
            ...PageDataInsightDetail
        }
    }
`
export default InsightDetail
