import React from "react"
import "bootstrap/dist/css/bootstrap-grid.min.css"
import "../../assets/css/index.scss"
import AboutUsPage from "../about"

const AboutUsPageTR = () => {
  return <AboutUsPage locale="tr" />
}


export default AboutUsPageTR
