import React from "react"
import "./locations-section.scss"
import RichText from "../../shared/rich-text/rich-text"
import FadeInConfig from "../../shared/animate-config"

const LocationsSection = ({ locations }) => {
  return (
    <div className="bg-light">
      <div className="locations-section pt-24 pb-12 ">
        <div className="container">
          <div className="row">
            <div className="grid grid-cols-1 lg:grid-cols-2 p-0">
              {locations.map((address, index) => {
                return (
                  <div key={index} className="locations-section__item px-16 mb-14"  {...FadeInConfig}>
                    <div className="locations-section__item--heading mb-2">{address.heading}</div>
                    <div className="locations-section__item--content"><RichText data={address.body} /></div>
                    <div className="locations-section__item--footer mt-2"><a
                      href={`mailto:${address.email}`}>{address.email}</a>
                    </div>
                    {address.imagesToShow && address.imagesToShow.length ? <div className="mt-2">
                      <img alt="address" src={address.imagesToShow[0].url}/>
                    </div> : null}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationsSection
