import React from "react"
import { graphql } from "gatsby"
import CareerOpItem from "../../../components/career/career-op-item/career-op-item"
import PopupLayout from "../../../components/layout/popup-layout"
import CareerHeader from "../../../components/career/career-header/career-header"
import NotFoundPage from "../../404"

export const query = graphql`
    query JobsQuery($url: String!) {
        strapiJobs(url: { eq: $url } locale: { eq: "en" }) {
            heading
            PublishDate
            EmploymentStatus
            JobLocation
            Experience
            Vacancy
            Content
            JobUrl
        }
    }
`

const Job = ({ data, locale = "en" }) => {
  const job = data.strapiJobs

  if (!job || !job.Content) return <NotFoundPage />

  return (
    <>
      <PopupLayout>
        <CareerHeader job={job} locale={locale} />
        <CareerOpItem data={job?.Content} />
      </PopupLayout>
    </>
  )

}

export default Job
