import React, { useEffect } from "react"
import "./back-dropped.scss"
import LanguageSwitcherDisplay from "../language-switcher/language-switcher-display"

export default function BackDropped({ locale }) {
  const [backDropOpen, setBackDropOpen] = React.useState(false)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.toggleLanguageSwitcher = () => {
        const widthBefore = document.querySelector("body").getBoundingClientRect().width
        document.querySelector("html").classList[backDropOpen ? "remove" : "add"]("back-drop-on")
        const widthAfter = document.querySelector("body").getBoundingClientRect().width
        const diff = widthBefore - widthAfter
        document.querySelector("html").style.paddingRight = `${diff > 0 ? 0 : Math.floor(Math.abs(diff))}px`
        document.querySelector(".language-switcher-display__wrapper")
          .style.transform = `translateX(-${diff > 0 ? 0 : Math.floor(Math.abs(diff / 2))}px`
        setBackDropOpen(!backDropOpen)
      }
    }
  }, [backDropOpen, setBackDropOpen])

  const handleClick = (e) => {
    if (e.target.classList.contains("back-drop")) window.toggleLanguageSwitcher()
  }

  return (
    <div className={`back-drop ${backDropOpen ? "open" : "closed"}`} role="none" onClick={handleClick}
         onKeyDown={handleClick}>
      <LanguageSwitcherDisplay visible={backDropOpen} locale={locale} />
    </div>
  )
}

