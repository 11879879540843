import React from "react"
import "./language-switcher-display.scss"
import GridBorder from "../../common/grid-border/grid-border"
import LanguageBtn from "./language-btn"
import { graphql, useStaticQuery } from "gatsby"
import getLocaleEntities from "../../../helpers/localization-helper"
import { merge } from "lodash"

const query = graphql`
    query {
        strapiGlobal {
            locale
            translations {
                phrase
                translated
            }
            localEntities {
                locale
                translations {
                    phrase
                    translated
                }
            }
        }
    }
`

export default function LanguageSwitcherDisplay({ visible, locale }) {
  const servicePath = "strapiGlobal"
  const viewData = useStaticQuery(query)
  const { translations } = getLocaleEntities({viewData, servicePath, locale})
  const phrases = merge(...translations.map(item => {return {[item.phrase]: item.translated}}));

  /*const languages = ["tr", "en", "de", "ar"]*/
  const languages = ["en"];
  const close = () => {
    window.toggleLanguageSwitcher()
  }

  return (
    <div className={`language-switcher-display dark-noised ${visible ? "open" : "closed"}`}>
      <div className="language-switcher-display__wrapper">
        <GridBorder mode="light" />
        <div className="container">
          <button className="language-switcher-display__close-btn" onClick={close}> {phrases["close"]}</button>
          <div className="language-switcher-display__heading pt-16 pb-12 row"> {phrases["change_language"]}</div>
          <div className="row">
            {languages.map((lang, index) => {
              return (
                <div className="col-6 col-md-3 pb-16 px-0" key={index}>
                  <LanguageBtn key={lang} lang={lang} activeLocale={locale}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
