import React from "react"
import RichText from "../../shared/rich-text/rich-text"
import "./product-item.scss"

const ProductItem = ({ key, heading, subheading, logo, content, images, slug, comingSoon, details, links }) => {
  return <div className="product-item" key={key} id={slug}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-3">
          <div className="product-item__logo text-center">
            <img className="inline-block" src={logo.url} alt={logo.alternativetext} />
          </div>
        </div>
        <div className="col-12 col-md-6 px-12 p-md-0">
          <div className="product-item__title">
            <div className="flex mb-1 align-items-center">
              <h2 className="heading inline-block">{heading}</h2>
              {comingSoon === "enabled" ? <span className="coming-soon inline-block align-middle">COMING SOON</span> : ""}
            </div>
            <h3 className="subheading">{subheading}</h3>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 offset-md-3 col-md-6 px-12 p-md-0">
          <div className="product-item__content">
            <RichText data={content} />
          </div>
        </div>
      </div>

      <div className="images">
        <div className="row">
          {images.map((image, index) => {
            return <div className="col-12 col-md-3 mb-8 mb-md-0" key={index}>
              <img className="product-image img-fluid w-full" src={image.url} alt={image.alternativetext} />
            </div>
          })}
        </div>
      </div>

      <div className="row">
        <div className="col-12 offset-md-3 col-md-6 px-12 p-md-0 product-item__detail">
          <RichText data={details} />
          {
            links.length && links[0].link ?
              <div className="product-item__links">
                {links.map((link, index) => {
                  return <a className="link-item-anchor" href={link.link} key={index}>
                      <img src={link.media.url} alt="" />
                    </a>
                })}
              </div>
              : ""
          }

        </div>
      </div>
    </div>
  </div>
}
export default ProductItem
