import React from "react"
import "./footer-nav.scss"
import ActionComponent from "../../common/action-component/action-component"
import { graphql, useStaticQuery } from "gatsby"
import FadeInConfig from "../animate-config"

const query = graphql`
    query { 
        strapiStaticPagesEN: allStrapiStaticPages(
            sort: {order: ASC, fields: published_at}
            limit: 3
            filter: {showOnFooter: { eq: true }, locale: {eq: "en"}}
        ) {
            nodes { ...PageDataStaticPages }
        }
        strapiStaticPagesTR: allStrapiStaticPages(
            sort: {order: ASC, fields: published_at}
            limit: 3
            filter: {showOnFooter: { eq: true }, locale: {eq: "tr"}}
        ) {
            nodes { ...PageDataStaticPages }
        }
        strapiStaticPagesDE: allStrapiStaticPages(
            sort: {order: ASC, fields: published_at}
            limit: 3
            filter: {showOnFooter: { eq: true }, locale: {eq: "de"}}
        ) {
            nodes { ...PageDataStaticPages }
        }
        strapiStaticPagesAR: allStrapiStaticPages(
            sort: {order: ASC, fields: published_at}
            limit: 3
            filter: {showOnFooter: { eq: true }, locale: {eq: "ar"}}
        ) {
            nodes { ...PageDataStaticPages }
        }
    }
`

const FooterNav = ({ heading, subheading, action, menuItems, locale }) => {
  const staticPages = useStaticQuery(query)['strapiStaticPages' + locale.toUpperCase()].nodes
  const localePrefix = locale !== "en" ? `/${locale}` : ""

  return (
    <nav className="footer-nav">
      <div className="container py-24">
        <div className="row">

          <div className="col-md-6 p-md-0 mb-16 md:mb-0">
            <div className="footer-heading"  {...FadeInConfig}>{heading}</div>
            <div className="footer-subheading"  {...FadeInConfig}>{subheading}</div>
            <ActionComponent type={action.type} className="block mt-4" endIcon={action.endIcon} text={action.text} theme={action.theme} src={action.linkTarget} />
          </div>

          <div className="col-6 col-md-3 p-md-0">
            {staticPages.map((staticPage, index) => <ActionComponent
                                                    className="footar-nav__item block p-0"
                                                    text={staticPage.pageSettings.title}
                                                    key={index}
                                                    src={`/static/${localePrefix}${staticPage.url}`}
                                                    theme={staticPage.theme || "dark"}
              />
            )}

          </div>

          <div className="col-6 col-md-3 p-0">
            <div className="grid grid-cols-2 p-0">
              <div>
                {
                  menuItems.slice(0, 4)
                    .map((item, index) => (
                      <ActionComponent key={index} className="footar-nav__item block p-0" text={item.text}
                                       theme={item.theme || "dark"}
                                       src={item.target} />)
                    )
                }
              </div>
              <div>
                {
                  menuItems.slice(4, 8)
                    .map((item, index) => (
                      <ActionComponent key={index} className="footar-nav__item block p-0" text={item.text}
                                       theme={item.theme || "dark"}
                                       src={item.target} />)
                    )
                }
              </div>
            </div>
          </div>

        </div>
      </div>
    </nav>
  )
}

export default FooterNav
