import React from "react"
import ProductsPage from "../products"

const ProductsPageTR = () => {

  return (
    <ProductsPage locale="tr" />
  )
}

export default ProductsPageTR
