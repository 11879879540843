import React from "react"
import PropTypes from "prop-types"
import Seo from "../shared/seo"
import Header from "../shared/header/header"
import VideoScreen from "../shared/videoScreen/video-screen"
import GalleryScreen from "../shared/galleryScreen/gallery-screen"

const BasicLayout = ({ children, seo, type, path, wrapperClass = "", locale = "en", videoLink, setVideoLink }) => {
  return <div className={"page-wrapper " + wrapperClass}>
    <Seo seo={seo} />
    <Header type={type} path={path} locale={locale} />
    <main>{children}</main>
    <VideoScreen view="currentVidBox" videoLink={videoLink} setVideoLink={setVideoLink} />
    <GalleryScreen />
  </div>
}

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default BasicLayout
