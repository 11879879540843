import React from "react"
import { graphql } from "gatsby"
import Footer from "../../components/shared/footer/footer"
import BackDropped from "../../components/shared/back-dropped/back-dropped"
import ArticleHeader from "../../components/insights/article-header/article-header"
import "../../components/insights/insights.scss"
import BasicLayout from "../../components/layout/basic-layout"
import RichText from "../../components/shared/rich-text/rich-text"
import NotFoundPage from "../404"

export const query = graphql`
    fragment PageDataStaticPages on StrapiStaticPages {
        locale
        url
        pageSettings {
            title
            headerTheme
        }
        content
    }

        query StaticPagesQuery($url: String!){
        strapiStaticPagesEN: strapiStaticPages(url:{eq: $url} locale: {eq: "en"}) {
           ...PageDataStaticPages
        }
        strapiStaticPagesTR: strapiStaticPages(url:{eq: $url} locale: {eq: "tr"}) {
           ...PageDataStaticPages
        }
        strapiStaticPagesDE: strapiStaticPages(url:{eq: $url} locale: {eq: "de"}) {
           ...PageDataStaticPages
        }
        strapiStaticPagesAR: strapiStaticPages(url:{eq: $url} locale: {eq: "ar"}) {
           ...PageDataStaticPages
        }
    }
`

const StaticPage = ({ data, locale = 'en'}) => {
  const pageData = data['strapiStaticPages' + locale.toUpperCase()]
  if (!pageData) return <NotFoundPage />
  if (!pageData?.pageSettings?.title) return <NotFoundPage />

  return <>
    <BasicLayout
      grid={false}
      theme="dark"
      path="/static"
      locale={locale}
      seo={{ title: pageData.pageSettings.title }}
      wrapperClass="bg-light"
    >
      <ArticleHeader title={pageData.pageSettings.title} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">

            <RichText data={pageData.content} />
          </div>
        </div>
      </div>
    </BasicLayout>
    <Footer locale={locale}/>
    <BackDropped locale={locale} />
  </>
}

export default StaticPage
