import React from "react"
import "./hero-section.scss"
import ActionComponent from "../../common/action-component/action-component"

const HeroSection = ({children, heading, topText, uid, actions, theme="dark"}) => {

  return (
    <>
      <div className={uid + " hero-section-component container mt-12 mt-md-20 " + theme}>
        <div className="hero-content mb-16">
          <div className="row">
            <div className="col-md-7 p-md-0">
              <span className="hero-topper">{topText}</span>
              <h1>{heading}</h1>
            </div>
          </div>
        </div>
        <div className="hero-cta py-5">
          <div className="row">
            {
              actions?.map((action, index) => {
                return (
                  <div key={index} className={action.wrapperClass || "col-6 col-md-3 offset-md-0 p-0"}>
                    <ActionComponent type="bordered" src={action.linkTarget || ""} {...action}/>
                  </div>
                )}
              )
            }
          </div>
        </div>
        {children ? <>{children}</> : ""}
      </div>
    </>
  )
}

export default HeroSection
