import React from "react"
import "./article-header.scss"

const ArticleHeader = ({ title, subtitle }) => {
  return <div className="insight-article-header container">
    <div className="row">
      <div className="col-10 offset-1">
        <h1 className="title">{title}</h1>
        {subtitle ? <h2 className="subtitle">{subtitle}</h2> : ""}
      </div>
    </div>
  </div>
}

export default ArticleHeader
