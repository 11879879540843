import React from "react"
import { graphql } from "gatsby"
import Footer from "../../../components/shared/footer/footer"
import BackDropped from "../../../components/shared/back-dropped/back-dropped"
import ArticleHeader from "../../../components/insights/article-header/article-header"
import "../../../components/insights/insights.scss"
import BasicLayout from "../../../components/layout/basic-layout"
import RichText from "../../../components/shared/rich-text/rich-text"
import NotFoundPage from "../../404"

export const query = graphql`
    query StaticPagesQueryTR ($url: String!){
        strapiStaticPages (url:{eq: $url} locale: {eq: "tr"}) {
            ...PageDataStaticPages
        }
    }
`

const StaticPage = ({ data , locale = 'tr'}) => {
  const pageData = data.strapiStaticPages
  if (!pageData?.pageSettings?.title) return <NotFoundPage />

  return <>
    <BasicLayout
      grid={false}
      theme="dark"
      path="/static"
      locale={locale}
      seo={{ title: pageData?.pageSettings.title }}
      wrapperClass="bg-light"
    >
      <ArticleHeader title={pageData?.pageSettings.title} />
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <RichText data={pageData.content} />
          </div>
        </div>
      </div>
    </BasicLayout>
    <Footer locale={locale}/>
    <BackDropped locale={locale} />
  </>
}

export default StaticPage
