import React from "react"

import "./product-list.scss"
import ProductItem from "../product-item/product-item"
import { graphql, useStaticQuery } from "gatsby"

const ProductList = ({ locale }) => {
  const viewData = useStaticQuery(query)
  const localizedData = viewData.strapiProductsPage.localEntities.find(item => item.locale === locale) || viewData.strapiProductsPage
  return localizedData.productDetail.map((product, index) => (
      <ProductItem {...product} key={index} />
    ))

}


const query = graphql`
    fragment pageDataProductDetail on StrapiProductsPage {
        locale
        productDetail {
            heading
            subheading
            slug
            logo {
                url
                alternativeText
            }
            content
            images {
                alternativeText
                url
            }
            comingSoon
            details
            links {
                media {
                    url
                }
                title
                content
            }
        }

        # Duplicated above Query for localizations
        # @TODO Use Fragment here if possible instead of duplicating 

        localEntities {
            locale
            productDetail {
                heading
                subheading
                slug
                logo {
                    url
                    alternativeText
                }
                content
                images {
                    alternativeText
                    url
                }
                comingSoon
                details
                links {
                    media {
                        url
                    }
                    title
                    content
                }
            }
        }

    }
    query ProductsPageDetailQuery {
        strapiProductsPage {
            ...pageDataProductDetail
        }
    }
`


export default ProductList
